import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/Emails/Browse',
    name: 'EmailsBrowse',
    meta: {
      title: 'Consulta de Emails Enviados',
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "EmailsBrowse" */ '../views/Emails/BrowseEmails.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((<any>window).VueUtils.RouterBeforeEach);

export default router
